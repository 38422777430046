import { getIsRedirectedFromSsr } from 'helpers/isServer';
import { getStringWithoutLeadingSlash } from 'helpers/parsing/stringWithoutSlash';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';
import { NextIncomingMessage } from 'next/dist/server/request-meta';

export const getSlugFromUrl = (originalUrl: string): string => {
    return getStringWithoutLeadingSlash(getUrlWithoutGetParameters(originalUrl));
};

export const getSlugFromServerSideUrl = (
    originalUrl: string,
    requestHeaders: NextIncomingMessage['headers'],
): string => {
    if (getIsRedirectedFromSsr(requestHeaders)) {
        return getStringWithoutLeadingSlash(getUrlWithoutGetParameters(originalUrl));
    }
    const lastUrlSegment = originalUrl.split('/').pop()!;
    const beforeExtensionSegment = lastUrlSegment.split('.')[0];
    const strippedSlug = beforeExtensionSegment.split('?')[0];

    return strippedSlug;
};

export const getCatNumFromServerSideUrl = (originalUrl: string): string | null => {
    if (originalUrl.includes('product/') || originalUrl.includes('video/')) {
        const urlSegments = originalUrl.split('/');

        return urlSegments[urlSegments.length - 1] ?? null; // remove extension from segment
    }

    return null;
};

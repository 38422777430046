import SeoMeta from 'components/Basic/Head/SeoMeta';
import Breadcrumbs from 'components/Layout/Breadcrumbs/Breadcrumbs';
import CommonLayout from 'components/Layout/CommonLayout';
import Webline from 'components/Layout/Webline/Webline';
import ArticleDetail from 'components/Pages/ArticleDetail/ArticleDetail';
import { ArticleDetailSkeleton } from 'components/Pages/ArticleDetail/ArticleDetailSkeleton';
import { mapArticleDetail } from 'connectors/articleInterface/article/Article';
import {
    ArticleDetailQueryApi,
    ArticleDetailQueryDocumentApi,
    ArticleDetailQueryVariablesApi,
    ProductsByCatnumsDocumentApi,
    useArticleDetailQueryApi,
} from 'graphql/generated';
import { createClient } from 'helpers/createClient';
import { initServerSideProps, ServerSidePropsType } from 'helpers/InitServerSideProps';
import { getIsRedirectedFromSsr } from 'helpers/isServer';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';
import { getSeoTitleAndDescriptionForFriendlyUrlPage } from 'helpers/seo/getSeoTitleAndDescriptionForFriendlyUrlPage';
import { getServerSidePropsWrapper } from 'helpers/serverSide/getServerSidePropsWrapper';
import { handleServerSideErrorResponseForFriendlyUrls } from 'helpers/serverSide/handleServerSideErrorResponseForFriendlyUrls';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useGtmFriendlyPageView } from 'hooks/gtm/useGtmFriendlyPageView';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { NextPageExtended } from 'types/global';
import { CombinedError, OperationResult } from 'urql';
import { getSlugFromServerSideUrl, getSlugFromUrl } from 'utils/getSlugFromUrl';
import { parseCatnums } from 'utils/grapesJsParser';
import { useGtmPageViewEvent } from 'utils/Gtm/EventFactories';
import { getGtmPageInfoForFriendlyUrl } from 'utils/Gtm/Gtm';

const ArticleDetailPage: NextPageExtended<ServerSidePropsType> = () => {
    const router = useRouter();
    const slug = getUrlWithoutGetParameters(router.asPath);
    const [{ data: articleDetailData, fetching, error }] = useArticleDetailQueryApi({
        variables: { urlSlug: getSlugFromUrl(slug) },
    });

    useQueryError(error, error instanceof CombinedError);

    const mappedArticleDetail = useMemo(() => {
        if (articleDetailData?.article) {
            return mapArticleDetail(articleDetailData.article);
        }

        return null;
    }, [articleDetailData?.article]);

    const articleDetailSeoTitleAndDescription = mappedArticleDetail
        ? getSeoTitleAndDescriptionForFriendlyUrlPage(mappedArticleDetail)
        : undefined;

    const gtmArticleDetailUrlPageViewEvent = useGtmPageViewEvent(
        getGtmPageInfoForFriendlyUrl(mappedArticleDetail, slug, mappedArticleDetail?.breadcrumb),
    );
    useGtmFriendlyPageView(gtmArticleDetailUrlPageViewEvent, slug, fetching);

    return (
        <CommonLayout showLastVisitedProducts isFetchingData={fetching}>
            <SeoMeta
                title={articleDetailSeoTitleAndDescription?.title}
                description={articleDetailSeoTitleAndDescription?.description}
            />
            {mappedArticleDetail?.breadcrumb && (
                <Webline>
                    <Breadcrumbs key="breadcrumb" breadcrumb={mappedArticleDetail.breadcrumb} />
                </Webline>
            )}
            {mappedArticleDetail ? <ArticleDetail article={mappedArticleDetail} /> : <ArticleDetailSkeleton />}
        </CommonLayout>
    );
};

ArticleDetailPage.layout = 'plain';

export const getServerSideProps = getServerSidePropsWrapper(
    ({ domainConfig, redisClient, ssrExchange, t }) =>
        async (context) => {
            const client = createClient({
                context,
                ssrExchange,
                publicGraphqlEndpoint: domainConfig.publicGraphqlEndpoint,
                t,
                redisClient,
            });

            const articleResponse: OperationResult<ArticleDetailQueryApi, ArticleDetailQueryVariablesApi> = await client
                .query(ArticleDetailQueryDocumentApi, {
                    urlSlug: getSlugFromServerSideUrl(context.req.url ?? '', context.req.headers),
                })
                .toPromise();

            const parsedCatnums = parseCatnums(articleResponse.data?.article?.text ?? ['']);
            await client
                .query(ProductsByCatnumsDocumentApi, {
                    catnums: parsedCatnums,
                })
                .toPromise();

            if (getIsRedirectedFromSsr(context.req.headers)) {
                const serverSideErrorResponse = handleServerSideErrorResponseForFriendlyUrls(
                    articleResponse.error?.graphQLErrors,
                    articleResponse.data?.article,
                    context.res,
                );

                if (serverSideErrorResponse) {
                    return serverSideErrorResponse;
                }
            }

            const initServerSideData = await initServerSideProps({ client, context, domainConfig, ssrExchange });

            return initServerSideData;
        },
);
export default ArticleDetailPage;
